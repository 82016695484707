<template>
  <div class="items-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Widget>
      <DataTable
        class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        :value="items"
        :filters.sync="tableState.filters"
        :lazy="true"
        :loading="isLoading"
        :paginator="true"
        :totalRecords="getItemCount"
        :rows.sync="tableState.pagination.pageSize"
        :first="pageOffset"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :currentPageReportTemplate="
          $t('show') +
            ' {first} ' +
            $t('to') +
            ' {last} ' +
            $t('of') +
            ' {totalRecords} ' +
            $t('records')
        "
        :rowsPerPageOptions="[10, 15, 20, 50, 100, 200]"
        dataKey="number"
        :rowHover="true"
        :stateKey="tableState.filterName"
        filterDisplay="row"
        :sortField="tableState.sortField"
        :sortOrder="tableState.sortOrder"
        stateStorage="local"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        @state-restore="onStateRestore($event)"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('items_masterdata') }}</h1>
            </div>
            <div class="table-header-right">
              <button class="btn btn-sm btn-inverse equal-btn" @click.prevent="onRemoveFilters()">
                <i class="fa fa-remove mr-2"></i>
                {{ $t('remove_filters') }}
              </button>
              <router-link class="btn btn-success mb-2 equal-btn" :to="{ name: 'ItemCreatePage' }">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('itemCreate') }}
              </router-link>
            </div>
          </div>
        </template>

        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column
          field="number"
          header="#"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '40px' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.number === '_new'">
              {{ $t('_new') }}
            </span>
            <span v-else>
              <router-link
                :to="{
                  name: 'ItemEditPage',
                  params: { itemNumber: slotProps.data.number },
                }"
                >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
              >
            </span>
          </template>
        </Column>

        <Column
          field="wholesaleNr"
          :header="$t('wholesaleNr')"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '80px' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps">{{ slotProps.data.wholesaleNr }}</template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="industryNr"
          :header="$t('industryNr')"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '80px' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps">{{ slotProps.data.industryNr }}</template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="description"
          :header="$t('description')"
          sortable
          :showFilterMenu="false"
          :styles="{ width: '50%' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps"><span v-html="slotProps.data.description"></span> </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
          </template>
        </Column>

        <Column
          field="type"
          :header="$t('type')"
          sortable
          filterMatchMode="equals"
          :showFilterMenu="false"
          :headerStyle="{ width: '60px' }"
        >
          <template #body="slotProps">{{ $t(slotProps.data.type) }} </template>
        </Column>

        <Column
          field="inventory"
          :header="$t('inventory')"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '60px', 'text-align': 'right' }"
          :bodyStyle="{ 'text-align': 'center' }"
        >
          <template #body="slotProps">{{ slotProps.data.inventory }} </template>
        </Column>

        <Column
          field="unit"
          :header="$t('unit')"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '60px', 'text-align': 'right' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps"> {{ $t(slotProps.data.unit) }} </template>
        </Column>

        <!-- <Column field="price" :header="$t('price')" sortable>
          <template #body="slotProps">
            {{ $n(slotProps.data.price, 'currency') }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.price" />
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['price']" class="p-column-filter" />
          </template>
        </Column> -->
        <!-- <Column :ref="'rowEdit'" :rowEditor="true" bodyStyle="text-align:center"></Column> -->
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'ItemsPage',
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    Widget,
    InputText,
  },
  data() {
    return {
      loading: true,
      editingRows: [],
      backupRows: [],
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        inventory: { value: null, matchMode: FilterMatchMode.EQUALS },
        unit: { value: null, matchMode: FilterMatchMode.EQUALS },
        price: { value: null, matchMode: FilterMatchMode.EQUALS },
        wholesaleNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
        industryNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        pagination: { skip: 0, pageSize: 10 },
        sorting: { sortField: 'number', sortOrder: 1 },
        filters: this.defaultFilters,
        filterName: 'items-table-filters',
      },
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'getItems',
      'getItem',
      'getItemUnits',
      'getItemsByType',
      'getItemCount',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('items_masterdata') }];
    },
    items() {
      // return this.getItemsByType(this.itemType);
      return this.getItems;
    },
    pageOffset() {
      return this.tableState.pagination.skip * this.tableState.pagination.pageSize;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
  },
  methods: {
    ...mapActions([
      'fetchItems',
      'initItem',
      'itemLocalUpdate',
      'updateItem',
      'createItem',
      'itemCleanup',
      'fetchItemsPaginated',
    ]),
    filterDate(value, filter) {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === 'string' && filter.trim() === '')
      ) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return this.$date(value).indexOf(filter) >= 0 ? true : false;
    },
    onAddItem() {
      this.initItem();
      // this.$emit('row-edit-init', {data: {}, field: null, index: 3});
      // console.log(this.$refs);
    },
    onRowEditInit(event) {
      if (event.data._id !== '_new') {
        this.backupRows[event.index] = { ...this.getItem(event.data._id) };
      }
    },
    onRowEditCancel(event) {
      if (event.data._id === '_new') {
        this.itemCleanup();
      } else {
        this.itemLocalUpdate(this.backupRows[event.index]);
      }
    },
    async onRowEditSave(event) {
      try {
        if (event.data && event.data._id != '_new') {
          await this.updateItem(event.data);
        } else {
          const item = await this.createItem(event.data);
        }
      } catch (e) {
        // console.log(e);
      }
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination = { skip: event.page, pageSize: event.rows };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      this.tableState.sorting = this.defaultSorting;
      this.tableState.pagination = this.defaultPagination;
      this.tableState.filters = this.defaultFilters;

      localStorage.removeItem(this.tableState.filterName);
      await this.loadTableData();
    },

    async onStateRestore(event) {
      // this.setupFilters();

      if (this.tableState) {
        this.tableState.sorting.sortField = event.sortField
          ? event.sortField
          : this.defaultSorting.sortField;
        this.tableState.sorting.sortOrder = event.sortOrder
          ? event.sortOrder
          : this.defaultSorting.sortOrder;
        this.tableState.pagination.skip = 0;
        this.tableState.pagination.pageSize = event.rows;
      }
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchItemsPaginated({
        pagination: this.tableState.pagination,
        sorting: this.tableState.sorting,
        filters: { ...this.tableState.filters },
      });
    },
  },
  async created() {
    this.tableState.filters = this.defaultFilters;
    this.loading = false;
  },

  async mounted() {
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(2),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(2),
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(3),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(3),
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(4),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(4) {
  width: 175px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(5),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(5) {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(6),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(6) {
  width: 90px;
  text-align: right;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(7),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(7) {
  width: 100px;
  text-align: right;
}

.p-editable-column textarea,
.p-editable-column .p-inputtext {
  width: 100%;
}
.items-component {
  border: 0px solid rebeccapurple;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

.fill-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}
.flexbox-item-grow {
  flex: 1; /* same as flex: 1 1 auto; */
}
::v-deep .p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
.table-header-right .equal-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px; /* Adjust the height as needed */
  padding: 0 10px; /* Adjust the padding as needed */
  font-size: 14px; /* Adjust the font size as needed */
}

.table-header-right .equal-btn .fa {
  margin-right: 5px;
}
</style>
